import React , { Suspense }from 'react'
import Close from './CloseButton'


class About extends React.Component {
  
    handleClick () {
      this.props.setParentState({showAbout: false})
    }
  
 
    render () {
      return (
        <div className="about">
          <Close handleClick={this.handleClick.bind(this)} />
          <div className="about-col">
            {/* <a className="about-main-link" href="" target="_blank">Link</a><br></br> */}
            <br></br>
            <div>Transforming Desires<br/>
                virtual performance with Handi Kim<br/>
                3.12. 7-7:30 pm CET</div>
            <div>
            During this performance, Tereza (The Rodina) and Handi Kim will introduce nine playgrounds sculpted in the virtual Tetem space. Each sculpture represents one particular human desire and the impossibility to fulfil it: the uncontrollable thirst to have more money than would ever be needed, fame, material possessiveness, individuality, power, a need to be ecological, luxury-hunting, libidinal addiction to beauty and cleanness, extreme efficiency, fantasy worlds, and seeking exotic experiences & adventures.<br/><br/>
            The whole performance is live-streamed via The Overkill Festival.<br/><br/>
            Transforming Desires is part of Playbour City Engines exhibition questioning the ‘twilight zone’ between play and labour called PLAYBOUR. Previously, visitors could bid and buy unique playgrounds in an auction-like event called Transaction Party. Sold playgrounds have been sculpted by a South Korean designer Handi Kim in collaboration with The Rodina and added to the exhibition space of virtual Tetem. Each sculpture is dedicated to its buyer and visually expresses one desire.<br/><br/>
            Commissioned by Tetem. Supported by Mondriaan Fonds, Stimuleringsfonds, Gemeente Enschede and Provincie Overijssel.<br/><br/>

            <b><a href="https://therodina.com/">The Rodina</a> and <a href="https://handiii.kim/">Handi Kim</a><br /></b>
            3D Web Programming and Design            
            </div>
          </div>  
        </div>
      )
    }
  }
  
  
  export default About