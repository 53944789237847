import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const baseUrl = '/data/images/'

class TextPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handleClose = ()=>{ 
        this.props.history.push('/lobby');
    }

    render() {  
        return (
            <div className="container">
            <div className="artwork-container-close" onClick={this.handleClose}>Close</div>
            {/* <h1>{ this.props.name }</h1> */}
            <div>ahoj</div>
            </div>
        );
    }
}


export default TextPage