import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Instructions from './InstructionsNavigation'
import ClassicInstructions from './Instructions'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    top: '100%',
    left: '0%',
    // transform: 'translate(-50%,-50%)',
    width: '100%',
    // height: 60,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: 'none',
    // color: "#571f91",
    color: "#00ff00",
    // 'box-shadow': 'inset 0px 0px 10px rgba(0, 0, 0, 0.5)'
  },
  // bar: {
  //   borderRadius: 0,
  //   backgroundColor: '#6F997E',
  // },
}))(CircularProgress);

class Loading extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
          <div className="splashScreen">
            <h1 className="splashScreen-main-title">Loading assets ...</h1>
            <Instructions />
            <div className="splashScreen-title">
            <BorderLinearProgress size="50" thickness={5}/>
               {/* <img src="./images/loading.jpg"/> */}
            </div>
            <ClassicInstructions />
          </div>
      )
    }
  }
  
  
  export default Loading