import React from "react";
import { Link } from "react-router-dom";
import ChatBar from "./ChatBar"

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
        };
    }

    componentDidMount() {
      this.messages = this.props.firebase.database().ref("messages/");
      this.messages.limitToLast(20).once("value", (snapshot) => {});
      this.messages.limitToLast(15).on("child_added", (snapshot) => {
        this.setState({
            messages: this.state.messages.concat([
                {
                    ...snapshot.val(),
                    key: snapshot.key,
                },
            ]),
        });
    });
    }

    componentDidUpdate() {
      this.scrollToBottom();
    }

    componentWillUnmount() {
      this.messages.off()
    }

    scrollToBottom = () => {
        setTimeout(() => {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }, 10);
    };

    render() {
        return (
            <div className="chat">
                <div className="chat-messages">
                    {this.state.messages.map((message, i) => (
                        <Message
                            key={message.key}
                            message={message.message}
                            name={message.name}
                            photoURL={message.photoURL}
                            messageIndex={i}
                            messagesLenght={this.state.messages.length}
                        />
                    ))}
                    <div
                        style={{ float: "left", clear: "both" }}
                        ref={(el) => {
                            this.messagesEnd = el;
                        }}
                    ></div>
                </div>
                <ChatBar
                  firebase={this.props.firebase}
                />    
            </div>
        );
    }
}

export default Chat;

function Message(props) {
    let transparency =
        100 - (props.messagesLenght - props.messageIndex - 1) * 10;
    if (transparency < 20) transparency = 20;

    return (
        <div>
            <div
                className="chat-message"
                style={{ opacity: transparency + "%" }}
            >

                <div className="chat-content">
                    <div className="chat-name">{props.name}</div>
                    <div className="chat-content-message">{props.message}</div>
                </div>
            </div>
        </div>
    );
}
