import React, { Suspense } from "react";

import {
    Router as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom";

import { createBrowserHistory } from "history";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

import LandingPage from "./LandingPage";
import MenuButton from "./MenuButton";
// import OverlayButton from "./OverlayButton";
import Header from "./Header";
import Footer from "./Footer";
import Profile from "./Profile";
import About from "./About";
import Privacy from "./Privacy";
import Chat from "./chat/Chat";
import Page from "./pages/Page"

import Lobby from "./scenes/Lobby";

import {
    uniqueNamesGenerator,
    adjectives,
    names,
} from "unique-names-generator";

const config = {
    apiKey: "AIzaSyDO5_K3ZaylhZ5amQp7V8TOhyX9hIUBc5k",
    authDomain: "tetem-desires.firebaseapp.com",
    databaseURL: "https://tetem-desires.firebaseio.com/",
    storageBucket: "gs://tetem-desires.appspot.com",
};

firebase.initializeApp(config);

const history = createBrowserHistory();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            volume: 1,
            login: true,
            rooms: {
                lobby: { message: "", open: true, isLive: false },
            },
            displayInstructions: false,
            flash: "Master messagewill be there",
            chat: true,
            url: "",
        };

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    user: user,
                });

                if (user.displayName === null) {
                    const randomName = uniqueNamesGenerator({
                        dictionaries: [adjectives, names],
                        separator: " ",
                        style: "capital",
                    });
                    user.updateProfile({
                        displayName: randomName,
                    });
                    this.handleUserUpdate();
                }

                let dbUser = firebase
                    .database()
                    .ref("users/" + firebase.auth().currentUser.uid);
                dbUser.set({
                    username: firebase.auth().currentUser.displayName,
                });
                dbUser.onDisconnect().remove();
            } else {
            }
        });


        this.flash = firebase.database().ref("flash/");
        this.flash.on("value", (snapshot) => {
            this.setState({ flash: snapshot.val().message });
        });


        history.listen((location, action) => {
            this.setState({ url: location.location.pathname });
        });
    }


    handleInstructions = () => {
        this.setState({ displayInstructions: false });
    };

    handleMute = () => {
        this.state.volume === 0
            ? this.setState({ volume: 1 })
            : this.setState({ volume: 0 });
    };

    handleChatToggle = () => {
        this.state.chat === false
            ? this.setState({ chat: true })
            : this.setState({ chat: false });
    }

    enterTheApp = () => {
        if (this.state.user) {
            this.setState({ login: true });
            history.push("/desire");
        } else {
            firebase
                .auth()
                .signInAnonymously()
                .then((user) => {
                    this.setState({
                        login: true,
                        user: user
                    });
                    history.push("/desire");
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
        }
    };

    handleUserUpdate = () => {
        this.state.user.reload().then((updatedUser) => {
            this.setState({
                user: firebase.auth().currentUser,
            });
        });
    };

    getVolume = () => {
        return this.state;
    };

    render() {
        return (
            <Router history={history}>
                <div className="main-container">
                    <div className="header-container">
                        {this.state.user && this.state.login ? (
                            <div>
                                <Header
                                    volume={this.state.volume}
                                    handleMute={this.handleMute}
                                    rooms={this.state.rooms}
                                    url={this.state.url}
                                    flash={this.state.flash}

                                />
                                {this.state.chat ? <Chat                                     
                                    firebase={firebase}
                                    user={this.state.user} 

                                /> 
                                : ""}
                                {this.state.magicButton ? <MagicButton /> : ""}
                                <Footer
                                    volume={this.state.volume}
                                    handleMute={this.handleMute}
                                    handleChatToggle={this.handleChatToggle}
                                    rooms={this.state.rooms}
                                    chat={this.state.chat}
                                    user={this.state.user}
                                    logOut={() => {
                                        firebase.auth().signOut();
                                        this.setState({ user: null });
                                        this.setState({ login: false });
                                    }}
                                />
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>

                    <MenuButton
                        handleClick={() => this.setState({ showAbout: true })}
                    />
                    {/* <OverlayButton
                        history={history}
                    /> */}
                    {this.state.showAbout ? (
                        <About setParentState={this.setState.bind(this)} />
                    ) : null}

                    <Switch location={location}>
                        <PrivateRoute
                            path="/desire"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Lobby
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="d01.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        
    
                        <PrivateRoute
                            path="/profile"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Profile
                                firebase={firebase}
                                user={this.state.user}
                                handleUserUpdate={this.handleUserUpdate}
                                history={history}
                            />
                        </PrivateRoute>

                        <Route path="/privacy">
                            <Privacy />
                        </Route>
                        <Route path="/">
                            <LandingPage
                                handleOnClick={this.enterTheApp}
                                handleOnClickAccesible={
                                    this.enterTheAccesibleApp
                                }
                            />
                        </Route>
                    </Switch>
                    <Switch location={location}>
                        <Route path="/adventure">
                                <Page />
                        </Route>                                
                        <Route path="/beauty">
                                <Page />
                        </Route>                                
                        <Route path="/eco">
                                <Page />
                        </Route>                                
                        <Route path="/efficiency">
                                <Page />
                        </Route>                                
                        <Route path="/fame">
                                <Page />
                        </Route>                                
                        <Route path="/fantasy">
                                <Page />
                        </Route>                                
                        <Route path="/lifestyle">
                                <Page />
                        </Route>                                
                        <Route path="/luxury">
                                <Page />
                        </Route>                                
                        <Route path="/page">
                                <Page />
                        </Route>                                
                        <Route path="/wealth">
                                <Page />
                        </Route>                                
                    </Switch>
                </div>
            </Router>
        );
    }
}

function PrivateRoute(props) {
    return (
        <Route
            render={({ location }) =>
                props.user && props.login ? (
                    props.children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default App;
