import React from 'react'


class Instructions extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
        <div className="instructions-container-secondary">
            <div className="instructions-block">
                <div>How to navigate?</div><br></br>
                <div>
                    In order to move in the space,
                    use WASD or your arrows. Shift + arrows make you move faster :)<br /><br />

                    <br />

                    If you are stuck reload the page.
                </div>
            </div>
        </div>
      )
    }
  }
  
  
  export default Instructions