import React from 'react'

function Close(props) {

    return (
        <div className="menu-button-close" onClick={props.handleClick}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="30px" height="30px" viewBox="0 0 48.2 48.2" style={{'enableBackground':'new 0 0 48.2 48.2'}}>
                <rect x="2.3" y="22.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -9.9825 24.1)" width="43.6" height="4"></rect>
                <rect x="22.1" y="2.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -9.9825 24.1)" width="4" height="43.6"></rect>
            </svg>          
        </div>

    )
}
  
  
export default Close