import React from "react";

class ChatBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: "",
            messages: [],
        };

        this.messages = this.props.firebase.database().ref("messages/");
        // this.messages.limitToLast(10).on("child_added", (snapshot) => {
        //     this.setState({
        //         messages: this.state.messages.concat([
        //             {
        //                 ...snapshot.val(),
        //                 key: snapshot.key,
        //             },
        //         ]),
        //     });
        // });

        // this.messages.limitToLast(20).once("value", (snapshot) => {
        //     //console.log(snapshot);
        // });
    }

    componentDidMount() {}

    componentWillUnmount() {}

    handleChange = (e) => {
        this.setState({ input: e.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.input.length > 0) {
            this.messages
                .push({
                    name: this.props.firebase.auth().currentUser.displayName,
                    photoURL: this.props.firebase.auth().currentUser.photoURL,
                    userId: this.props.firebase.auth().currentUser.uid,
                    message: this.state.input,
                    timestamp: this.props.firebase.database.ServerValue
                        .TIMESTAMP,
                })
                .then(() => {
                    this.setState({
                        input: "",
                    });
                });
        }
    };

    render() {
        return (
            <div className="chat-bar">
                <div className="chat-input">
                    <ChatInput
                        type
                        handleSubmit={this.handleSubmit}
                        handleChange={this.handleChange}
                        value={this.state.input}
                    />
                </div>
            </div>
        );
    }
}

export default ChatBar;

class ChatInput extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        return (
            <form onSubmit={this.props.handleSubmit} className="chat-imput-form">
                <input
                    className="chat-input-field"
                    type="text"
                    value={this.props.value}
                    onChange={this.props.handleChange}
                />
                <input className="chat-input-submit" type="submit" value=" " />
            </form>
        );
    }
}
